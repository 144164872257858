












































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import moment from 'moment';
import DataSourceHandler from '@/apps/data/components/explorer/DataSourceHandler.vue';
import { Participant } from '@/models/study/models';
import { DataSourceOutputType } from '@/models/data/models';
import { globalStore } from '@/store/modules/global';

@Component({
  components: {
    DataSourceHandler,
  },
})
/**
 * Display data of a participant. The available roles and corresponding data templates are
 * fetched from the client app settings of the study app.
 *
 * Used components:
 * - DataSourceHandler
 */
export default class ParticipantDataExplorer extends Vue {
  @Prop({ required: true }) id: string;
  @Prop({ required: true }) participant: Participant;

  $refs: {
    dataSourceHandler: DataSourceHandler;
  };
  role = null;
  startDate: Date = new Date();

  @Watch('$route.query')
  onRouteChanged(): void {
    this.load();
  }

  get roleOptions(): { role: string; template: string }[] {
    return (
      globalStore.clientAppSetting('config')?.value?.device_assignment_roles ||
      []
    );
  }

  get template(): string | undefined {
    return this.roleOptions.find(o => o.role === this.role).template;
  }

  async mounted(): Promise<void> {
    await this.load();
  }

  async load(): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      const dateString = this.$routerHandler.query('')['date'];
      if (dateString) {
        this.startDate = new Date(dateString);
      }
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }

  get parameters(): {
    start_time: string;
    end_time: string;
    patient: string;
    role: string;
  } {
    const startDate = moment(this.startDate);
    const startT = startDate.startOf('day').toISOString();
    const endT = startDate.add(1, 'days').startOf('day').toISOString();
    return {
      start_time: startT,
      end_time: endT,
      patient: this.id,
      role: this.role,
    };
  }

  templateChanged(): void {
    if (this.role && this.template) {
      this.$refs.dataSourceHandler.removeAllDataSourceTemplate();
      this.$refs.dataSourceHandler.addDataSourceTemplate({
        template: this.template,
        parameters: this.parameters,
        output_type: DataSourceOutputType.TIME_SERIES,
      });
    }
  }

  paramsChanged(): void {
    this.$routerHandler.updateQuery('', { date: this.startDate.toISOString() });
    // Prepare data source request
    if (this.role) {
      this.$refs.dataSourceHandler.setTemplateParameters(this.parameters);
    }
  }
}
