import { apiClientV2 } from '@/api/ApiClientV2';
import { Token } from '@/models/core/profile';
import { DeviceRelation } from '@/models/data/models';
import { Device } from '@/models/device/models';
import { DeviceAssignment, Participant } from '@/models/study/models';
import { globalStore } from '@/store/modules/global';
import { v4 as uuidv4 } from 'uuid';
import { getMonitoringDays } from './helpers';
import {
  EulachConfig,
  EulachDeviceSetting,
  EulachDeviceSettings,
  ParticipantMeta,
  ParticipantObjects,
  StudyArm,
} from './interfaces';

/**
 * Generate objects
 * - device of corrent model
 * - device relation
 * - device assignment
 * - device setting
 * - token
 * @param participantID
 */
export async function createObjectsForParticipant(
  participant: Participant<ParticipantMeta>,
): Promise<[ParticipantObjects, string]> {
  const eulachConfig: EulachConfig = globalStore.clientAppSetting(
    'eulach_config',
  )?.value;
  if (!eulachConfig) {
    throw new Error('eulach_config is missing in client app settings.');
  }

  const studyArm = participant.meta?.studyArm;
  if (!studyArm) {
    throw new Error('studyArm is undefined.');
  }
  const device_id = `app-${studyArm}-${uuidv4()}`;

  const model = eulachConfig.models[studyArm];

  const device = await apiClientV2.create<Partial<Device>, Device>(Device, {
    model,
    device_id,
  });

  const role = `app-${studyArm}`;

  const deviceRelation = await apiClientV2.create<
    Partial<DeviceRelation>,
    DeviceRelation
  >(DeviceRelation, {
    device: device.id,
    application: participant.application,
    role,
  });

  const deviceAssignment = await apiClientV2.create<
    Partial<DeviceAssignment>,
    DeviceAssignment
  >(DeviceAssignment, {
    device_relation: deviceRelation.id,
    patient: participant.id,
    role,
    effective_time: new Date().toISOString(),
  });

  // Set device settings from client app settings
  const eulachDeviceSettings: EulachDeviceSettings = globalStore.clientAppSetting(
    'eulach_device_settings',
  )?.value;
  if (!eulachDeviceSettings) {
    throw new Error('eulach_device_settings client app setting is missing');
  }
  for (const setting of eulachDeviceSettings.device_settings) {
    const filteredSetting = filterDeviceSettingForControl(
      setting,
      participant.meta?.studyArm,
    );
    await Device.setSetting(
      apiClientV2,
      device.id,
      filteredSetting.key,
      filteredSetting.value,
    );
  }

  const monitoringDays = getMonitoringDays(participant.meta?.startDate);
  await Device.setSetting(apiClientV2, device.id, 'monitoring_days', {
    monitoring_days: monitoringDays,
  });

  const token = await apiClientV2.create<Partial<Token>, Token>(Token, {
    label: device_id,
    profile: eulachConfig.profile,
  });

  if (token.key === undefined) {
    throw new Error('There was an error while creating a token.');
  }

  return [
    {
      device: device.id,
      deviceRelation: deviceRelation.id,
      deviceAssignment: deviceAssignment.id,
      tokenId: token.id,
    },
    token.key,
  ];
}

function filterDeviceSettingForControl(
  setting: EulachDeviceSetting,
  studyArm?: StudyArm,
): EulachDeviceSetting {
  if (studyArm === 'control') {
    if (setting.key === 'activity_strength') {
      setting.value = {
        exercises: [],
      };
    }
  }
  return setting;
}

export async function revokeParticipantObjects(
  participantObjects: ParticipantObjects,
): Promise<void> {
  if (!participantObjects?.tokenId) {
    throw new Error('No object registered for this participant.');
  }
  await Token.revoke(participantObjects.tokenId);
  await apiClientV2.delete(
    DeviceAssignment,
    participantObjects.deviceAssignment,
  );
  await apiClientV2.delete(DeviceRelation, participantObjects.deviceRelation);
}
